import * as React from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Grid,
} from "@mui/material";
import TableHeader from "./TableHeader";

export default function TableEx({ data }) {
  const [detailedKey, setDetailedKey] = React.useState();
  const toHHMM = (MINUTES) => {
    var m = MINUTES % 60;
    var h = (MINUTES - m) / 60;

    return (
      (h < 10 ? "0" : "") +
      h.toString() +
      ":" +
      (m < 10 ? "0" : "") +
      m.toString()
    );
  };

  const handleDetailClick = (e, key) => {
    if (detailedKey === e) {
      setDetailedKey();
    } else {
      setDetailedKey(e);
    }
  };
  const sum = data
    .map((c) => c.totalWorkMins)
    .reduce((partialSum, a) => partialSum + a, 0);
  return (
    <Table>
      <TableHeader />
      <TableBody>
        {data.map((item) => (
          <>
            <TableRow
              key={item.date}
              style={{
                backgroundColor: item.isHoliday
                  ? "#ffedb7"
                  : item.isOfficialHoliday
                  ? "#ffbcb7"
                  : "unset",
              }}
            >
              <TableCell>{item.persianDate}</TableCell>
              <TableCell>{item.dayOfWeek}</TableCell>
              <TableCell>{toHHMM(item.totalWorkMins)}</TableCell>
              <TableCell>
                <Button onClick={handleDetailClick.bind(this, item.date)}>
                  View
                </Button>
              </TableCell>
            </TableRow>
            {item.worklogs.length > 0 &&
              detailedKey &&
              detailedKey === item.date && (
                <TableRow>
                  <TableCell colSpan={4} style={{ textAlign: "right", direction:'rtl' }}>
                    {item.worklogs.map((wl) => (
                      <Grid>
                        {wl.parent_name} ({wl.issue_name}){" "}
                        {toHHMM(wl.worklog_time_spend_minutes)}{" "}
                      </Grid>
                    ))}
                  </TableCell>
                </TableRow>
              )}
          </>
        ))}
        <TableRow>
          <TableCell></TableCell>
          <TableCell>Total</TableCell>
          <TableCell>{toHHMM(sum)}</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
