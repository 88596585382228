import * as React from "react";
import { TableHead, TableCell, TableRow } from "@mui/material";

export default function TableHeader() {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Date</TableCell>
        <TableCell>DayOfWeek</TableCell>
        <TableCell>TimeSpend</TableCell>
        <TableCell>Details</TableCell>
      </TableRow>
    </TableHead>
  );
}
