import { TextField, Grid, Container, MenuItem, Select, FormLabel } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import Stories from "./Stories";

function Storypoints() {
  const [form, setForm] = useState({
    code: "",
    date: localStorage.getItem("lastdate") || '1401/05/01',
    enddate: localStorage.getItem("lastenddate") || '1401/05/10',
  });
  const [submitting, setSubmitting] = useState(false);
  const [data, setData] = useState([]);

  const baseUrl = process.env.REACT_APP_BASEURL;
  const handleInput = (e) => {
    setForm((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
  };
  const handleReport = () => {
    localStorage.setItem("lastdate", form.date);
    localStorage.setItem("lastenddate", form.enddate);
    setData();
    setSubmitting(true);
    fetch(
      `${baseUrl}/GetStorypoints?startDate=${form.date
        .replace("/", "")
        .replace("/", "")}&endDate=${form.enddate
        .replace("/", "")
        .replace("/", "")}&code=${form.code}`
    )
      .then((res) => res.json())
      .then((res) => {
        setData(res);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Container spacing={2}>
      <Grid
        container
        spacing={1}
        style={{ margin: "8px", justifyContent: "center" }}
      >
        <Grid item width="250px">
          {" "}
          <Select
            size="small"
            title="Code"
            name="code"
            value={form?.code}
            fullWidth
            onChange={handleInput}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="U67D40DA7E2344D48BDC0E14322AEB18">
              Roudabeh
            </MenuItem>
            <MenuItem value="U7C73EE6B56743B68B2ADB6833069BC1">
              Setayesh
            </MenuItem>
            <MenuItem value="U70DEF32EF64C455585019BD597777C0">Mehran</MenuItem>
            <MenuItem value="U567D40D7E2344D48BDC0E14322AEB18">
              Alireza
            </MenuItem>
          </Select>
        </Grid>
        <Grid item lineHeight={"36px"} spacing={1}>
          <TextField
            size="small"
            title="FromDate"
            placeholder="YYYY/MM/DD"
            value={form?.date}
            name="date"
            onChange={handleInput}
          />
          <FormLabel style={{ margin: "6px" }}>To</FormLabel>
          <TextField
            size="small"
            title="EndDate"
            placeholder="YYYY/MM/DD"
            value={form?.enddate}
            name="enddate"
            onChange={handleInput}
          />
        </Grid>
        <Grid item alignItems={"center"}>
          <LoadingButton loading={submitting} onClick={handleReport}>
            Get Report
          </LoadingButton>
        </Grid>
      </Grid>
      {data && data.length > 0 && <Stories data={data} />}
    </Container>
  );
}

export default Storypoints;
