import "./App.css";
import { Grid, Button } from "@mui/material";
import { useEffect, useState } from "react";
import Timesheet from "./Timesheet/Timesheet";
import Storypoints from "./Storypoint/Storypoints";

import { LoadingButton } from "@mui/lab";
const baseUrl = process.env.REACT_APP_BASEURL;

function App() {
  const [updateDate, setUpdatedate] = useState("");
  const [activeTab, setActiveTab] = useState();
  const [submitting,setSubmitting] = useState(false);
  useEffect(() => {
    fetch(`${baseUrl}/GetLastUpdateDate`)
      .then((res) => res.json())
      .then((res) => {
        setUpdatedate(res);
      });
  }, []);

  const handleRefresh = () =>{
    setSubmitting(true);
    fetch(`${baseUrl}/Refresh`)
      .then((res) => res.json())
      .then((res) => {
        setUpdatedate(res);
        setSubmitting(false);
      });
  }
  let options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return (
    <Grid container>
      <Grid item xs={12} textAlign="center" margin="8px">
        {updateDate &&
          `Last Update Date: ${new Date(updateDate).toLocaleDateString(
            "fa-IR",
            options
          )}`}
        <LoadingButton
          variant="outlined"
          size="small"
          onClick={handleRefresh}
          loading={submitting}
        >
          Refresh
        </LoadingButton>
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant={activeTab === "timesheet" ? "contained" : "text"}
          onClick={() => setActiveTab("timesheet")}
        >
          Timesheets
        </Button>
        <Button
          variant={activeTab === "story" ? "contained" : "text"}
          onClick={() => setActiveTab("story")}
        >
          Storypoints
        </Button>
        <Button
          variant={activeTab === "details" ? "contained" : "text"}
          onClick={() => setActiveTab("details")}
        >
          Check Details
        </Button>
      </Grid>
      {activeTab && activeTab === "timesheet" && <Timesheet />}
      {activeTab && activeTab === "story" && <Storypoints />}
    </Grid>
  );
}

export default App;
