import * as React from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Grid,
  TableHead,
} from "@mui/material";

const ColoredBadge = ({ color, value }) => (
  <span
    style={{ backgroundColor: color, padding: "3px", margin:'2px', borderRadius: "10px" }}
  >
    {value}
  </span>
);

export default function Stories({ data }) {
  const [detailedKey, setDetailedKey] = React.useState();
  const toHHMM = (MINUTES) => {
    var m = MINUTES % 60;
    var h = (MINUTES - m) / 60;

    return (
      (h < 10 ? "0" : "") +
      h.toString() +
      ":" +
      (m < 10 ? "0" : "") +
      m.toString()
    );
  };

  const colors = {
    "To Do": "FF1E00",
    "In Progress": "EAE509",
    "Waiting RC": "EAE509",
    "RC Test": "EAE509",
    "WAITING PRODUCTION": "EAE509",
    Test: "EAE509",
    Done: "7DCE13",
  };

  const handleDetailClick = (e, key) => {
    if (detailedKey === e) {
      setDetailedKey();
    } else {
      setDetailedKey(e);
    }
  };

  const sumFor = (subs) =>
    subs
      .map((c) => c.wl_timespend)
      .reduce((partialSum, a) => partialSum + a, 0);

  const sum = data
    .map((c) => sumFor(c.subtasks))
    .reduce((partialSum, a) => partialSum + a, 0);

  const sumPoints = (status) =>
    data
      .filter((c) => c.status === status)
      .map((c) => c.story_point)
      .reduce((partialSum, a) => partialSum + a, 0);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Story</TableCell>
          <TableCell>Point</TableCell>
          <TableCell>Sprint</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Total Time Spend</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item) => (
          <>
            <TableRow
              key={item.story_name}
              style={{
                backgroundColor: `#${colors[item.status]}`,
              }}
            >
              <TableCell style={{ direction: "rtl", textAlign: "right" }}>
                {item.story_name}
              </TableCell>
              <TableCell>{item.story_point}</TableCell>
              <TableCell>{item.sprint}</TableCell>
              <TableCell>{item.status}</TableCell>
              <TableCell>{toHHMM(sumFor(item.subtasks))}</TableCell>
              <TableCell>
                <Button onClick={handleDetailClick.bind(this, item.story_name)}>
                  View
                </Button>
              </TableCell>
            </TableRow>
            {item.subtasks.length > 0 &&
              detailedKey &&
              detailedKey === item.story_name && (
                <TableRow>
                  <TableCell
                    colSpan={4}
                    style={{ textAlign: "right", direction: "rtl" }}
                  >
                    {item.subtasks.map((wl) => (
                      <Grid>
                        {wl.wl_author} ({wl.name}) {toHHMM(wl.wl_timespend)}{" "}
                      </Grid>
                    ))}
                  </TableCell>
                </TableRow>
              )}
          </>
        ))}
        <TableRow>
          <TableCell>Total</TableCell>
          <TableCell>
            <ColoredBadge
              color={`#${colors["Done"]}`}
              value={sumPoints("Done")}
            />
            <ColoredBadge
              color={`#${colors["In Progress"]}`}
              value={
                sumPoints("In Progress") +
                sumPoints("Waiting RC") +
                sumPoints("RC Test") +
                sumPoints("Test") +
                sumPoints("WAITING PRODUCTION")
              }
            />
            <ColoredBadge
              color={`#${colors["To Do"]}`}
              value={sumPoints("To Do")}
            />
          </TableCell>
          <TableCell>{toHHMM(sum)}</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
